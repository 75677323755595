.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  text-decoration: none;
}

.dateField {
  width: 90px;
}

.inputDateField {
  width: 100%;
  border: 1px solid black;
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
  width: 0;
}
.accordion-button::after {
  background-image: none;
  width: 0;
}

#editTextField {
    background-color: transparent;
    border: 0;
    padding: 0px;
}

#editTextField:hover{
  background-color: #d3d3d3;
  cursor: pointer;
}

#trade-add-container {
  border: solid 1px black;
  border-radius: 20px;
  margin: 10px;
  padding: 10px;
}

#dropDownList {
  background: transparent;
  border:none;
  color: black;
}
#dropDownList:after {
    display: none;
 }

#dropDownListHeader {
  background: transparent;
  border:none;
  color: black;
  font-weight: bold;
}

#dropDownListStd {
  background: transparent;
  color: black;
}


.rowOpen td {
  background-color: #f8fff8;
 }

#tradeSourceSelectLarge {
  background: transparent;
  border:none;
  color: black;
}

.editTable {
  width: 50%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header-profile {
  border: solid 1px black;
  font-size: 10px;
  font-weight: bold;
  color: black;
  text-overflow: clip;
  height: 25px;
  vertical-align:middle;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
